<template>
  <div class="addForm">
        <router-link to="/user/userTrade">
        <span @click="$router.go(-1)">二手信息</span>
        <!-- <span @click="$router.push('/user/userTrade')">二手信息</span> -->
        </router-link>
        <em>/</em>
        <span>编辑二手商品信息</span>
      <el-form ref="form" :model="form" label-width="80px" >
        <el-form-item label="标题">
            <el-input v-model="form.title"></el-input>
        </el-form-item>

        <el-form-item label="内容">
            <el-input  type="textarea" v-model="form.content"></el-input>
        </el-form-item>
        <!-- <el-form-item label="内容"  :model="ruleForm" :rules="rules" ref="ruleForm"  prop="articleBody">
            <quill-editor

                v-model="form.content"/>
      </el-form-item> -->
         <el-form-item label="联系方式">
            <el-input v-model="form.tel"></el-input>
        </el-form-item>
         <el-form-item label="价格">
             <el-input v-model.number="form.price"></el-input>
        </el-form-item>
          <el-form-item label="商品类别">
        <el-select v-model="form.types" placeholder="请选择活商品类别">
          <el-option label="无分类" value="0"></el-option>
          <el-option label="电子用品" value="1"></el-option>
          <el-option label="日常百货" value="2"></el-option>
          <el-option label="学习用品" value="3"></el-option>
          <el-option label="运动户外" value="4"></el-option>
          <el-option label="文化娱乐" value="5"></el-option>
          <el-option label="食品保健" value="6"></el-option>
           <el-option label="服饰鞋包" value="7"></el-option>
        </el-select>
        </el-form-item>
         <el-form-item label="商品图片(没选默认是原图)">

        <div class="block" >
            <div>
              <el-image
                style="width: 100px; height: 100px"
                :src="this.form.photo"
              @change="onFileChange "
              v-show="basicPhoto"
                > </el-image>
            </div>

           <div class="basicPhoto" v-show="!basicPhoto" @click="showPhoto" >
             <img src="../../../../public/images/加 (2).png" alt="">
             <!-- <input type="file" accept="image/*" @change="onFileChange" v-show="false" ref="fileRef" /> -->
           </div>
           <input type="file" accept="image/*" @change="onFileChange" v-show="false" ref="fileRef" />
      </div>
        </el-form-item>
        <el-form-item class="addBtn">
          <el-button type="primary" @click="saveTraBtn">下架存草稿</el-button>
            <el-button type="primary" @click="addTraBtn">发布</el-button>
          <router-link to="/user/userQuestion">
          <el-button @click="$router.go(-1)">取消</el-button>
          </router-link>
        </el-form-item>

        </el-form>

  </div>
</template>

<script>
import { editTrade ,getTradeDetail} from '../../../API/Trade.js'
import { mapActions } from 'vuex'
export default {
  name: 'addTrade',
  props:['traId'],
  data () {
    return {
      userName: '123',
      basicPhoto: false,
      file: null,
      types: ['无种类', '电子用品', '日常百货', '学习用品', '运动户外', '文化娱乐', '食品保健', '服饰鞋包'],
      form: {
        id:0,
        isdown:0,
        title: '',
        content: '',
        price: '',
        tel: '',
        photo: '',
        types: ''
      }
    }
  },
  methods: {
    ...mapActions(['$base64Img']),
    // getFile (state, e) {
    //   // 图片预览
    //   // console.log(e.target.files[0])
    //   this.file = e.target.files[0]
    //   const reader = new FileReader()
    //   reader.readAsDataURL(this.file) // 图片转为base64 然后显示在页面
    //   reader.onload = function (e) {
    //     this.form.photo = e.target.result
    //   }
    // },
    // // 图片上传
    // doUpload () {
    //   const formdata = new FormData()
    //   formdata.append('file', this.files)
    // },

    async  addTraBtn () {
      // console.log(typeof (this.form.price))
      if (this.form.title.trim() !== '' && this.form.content.trim() !== '' && this.form.tel.trim() !== '' && this.form.price !== '' && typeof (this.form.price) === 'number') {
        //   内容不为空时，发起请求

        // console.log(Object.prototype.toString.call(this.price))
        this.form.isdown=0
        // console.log(this.form)
        const { data: res } = await editTrade(this.form)
        if (res.status === 0) {
          this.$message({
            showClose: true,
            type: 'success',
            message: res.message
          })
          this.$emit('showTraInfo', true)
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: res.message
          })
        }
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: '内容不完整或价格输入有误'
        })
      }
    },
    async  saveTraBtn () {
      // console.log(typeof (this.form.price))
      if (this.form.title.trim() !== '' && this.form.content.trim() !== '' && this.form.tel.trim() !== '' && this.form.price !== '' && typeof (this.form.price) === 'number') {
        //   内容不为空时，发起请求

        // console.log(Object.prototype.toString.call(this.price))
        this.form.isdown=1
        // console.log(this.form)
        const { data: res } = await editTrade(this.form)
        if (res.status === 0) {
          this.$message({
            showClose: true,
            type: 'success',
            message: res.message
          })
          // this.$emit('showTraInfo', true)
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: res.message
          })
        }
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: '内容不完整或价格输入有误'
        })
      }
    },
    getDetail() {
      // var id = parseInt(this.$route.params.queId)
      this.$nextTick(async () => {
        const { data: res } = await getTradeDetail(this.traId)
        // console.log(res,'getdetail trade from sql')
        if (res.status === 0) {
          this.traInfo = res.data
          this.form.id=this.traInfo.id
          this.form.title=this.traInfo.title
          this.form.content=this.traInfo.content
          this.form.isdown=this.traInfo.isdown
          this.form.price=this.traInfo.price
          this.form.photo=this.traInfo.photo
          this.form.tel=this.traInfo.tel
          this.form.types=this.traInfo.types
          // console.log(this.traInfo,this.form,'getdetail trade this.form')
        } else {
          this.$message({
            type: 'error',
            message: res.message
          })
        }
      })
      //   console.log(this.$route.params.schArtId)
    },
    isphoto () {
      if (this.form.photo !== undefined) {
        this.basicPhoto = true
      } else {
        this.basicPhoto = false
      }
    },
    showPhoto () {
      // this.basicPhoto = true
      this.$refs.fileRef.click()
    },

    // 将上传图片转为base64
    onFileChange (e) { // 通过input标签选择文件传入e
      // this.doUpload(e)
      this.$base64Img(e).then(async (res) => { // 调用全局方法
      // console.log(res) // 控制台打印出图片base64值
        if (res.status !== 0) {
          this.$message({
            type: 'error',
            message: res.message
          })
        } else {
          var obj = {
            photo: res.data
          }
          this.form.photo = res.data
          this.isphoto()
          // console.log(obj)
          // 将base64传入数据库
          // 重新获取用户信息
          return obj
        }
      })
    }
  },
  created(){
    this.getDetail()
  },
  wath:{
    traId(newId){
      this.traId=newId
      this.getDetail()
    }
  }

}
</script>

<style lang="less" scoped>
// *{
//     margin: 0;
//     padding: 0;
// }
.addForm{
    background-color: #fff;
    .el-form {
        margin: 10px auto;
        width: 80%;
       /deep/ .el-textarea{
            .el-textarea__inner{
                min-height: 2.1667rem !important;
            }
        }
        .addBtn {

        padding: 20px 0;
        display: flex;
        justify-content: center;
         /deep/ .el-form-item__content {
            margin: 0 !important ;
            button{
                margin: 0 .1667rem;
            }
        }
    }

    }

    span{
            display: inline-block;
            text-align: left;
            height: 48px;
            line-height: 48px;
            font-size: 12px;
            cursor: pointer;
            color: #999aaa;
            padding-left: .1333rem;
            margin: 10px 0;
        }
        span:hover{
              color:#ff3535
            }
        em{
          margin: .0833rem;
        }
        .basicPhoto{
          width: 2.5rem;
          height: 2.5rem;
          border: 1px dotted #000;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          img{
            display: inline-block;
            width: .4167rem;
            height: .4167rem;
          }
        }
}
</style>
